/* Locations */
.locations ul {
  padding-left: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 20px;
}

.locations li {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  background-color: var(--background-color-light);
  margin-bottom: 1rem;
  min-height: 80px;
  padding: .5rem .5rem 1.5rem .5rem;
  position: relative;
}

.location {
  display: flex;
  text-align: left;
  font-size: 0.9rem;
  line-height: 1;
  padding-right: 1rem;
}

.location-name {
  font-weight: bold;
  font-size: 1rem;
}

.contact-detail {
  margin-right: 0.25rem;
}

.location-icon {
  margin: 1rem 0.5rem 0 0;
  font-size: 2rem;
  color: #281d47;
}

.location-images img{
  margin: 0 1rem 1rem 0;
  width: 45.9%;
}

@media screen and (max-width: 550px) {
  /* Locations */
  .locations ul {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-row-gap: 1rem;
    grid-column-gap: 20px;
  }

  .locations li {
    max-width: 87.5%;
    margin: 1rem;
  }

  .location-images img {
    width: 44%;
  }

}