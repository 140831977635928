.locations-icon {
  margin: 2rem 0 0;
  font-size: 4rem;
  color: #281d47;
}

.tab-icon {
  position: relative;
  top: 2px;
  right: 4px;
}

/* Tab Container */
.tabs {
  width: 100%;
  height: auto;
  min-height: 400px;
  background: transparent;
  margin: 0 auto;
  padding: 2rem 1rem 0;
  color: var(--text-color-alt);
  border-radius: 2rem;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: 2rem;
  padding-left: 0px;
}

.react-tabs__tab {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}

.react-tabs__tab--selected {
  background: var(--primary-color);
  color: var(--white);
}

.react-tabs__tab--disabled {
  color: var(--text-color-alt);
  cursor: default;
}

.react-tabs__tab:hover {
  background: var(--primary-color-alt);
  color: var(--white);
}

.react-tabs__tab:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

.react-tabs__tab:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  font-size: 2rem;
  text-align: center;
}

.react-tabs__tab:focus {
  outline: none !important;
}

