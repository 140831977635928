.scroll-to-top {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 45px;
  z-index: 20;
}

.icon-style {
  background-color: var(--primary-color);
  border-radius: 50%;
  height: 45px;
  width: 45px;
  color: #fff;
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: var(--primary-color-alt);
  color: #fff;
}