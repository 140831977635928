/* Map */
.map-container {
  margin: 1rem auto 0;
  width: 100%;
  height: 500px;
}

@media screen and (max-width: 550px) {
  /* Map */
  .map-container {
    margin: 0 auto;
    padding: 0;
    width: 95%;
    height: 500px;
    /*position: relative;
    right: 1rem;*/
  }

}