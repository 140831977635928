:root {
  --background-color: #fdfdfd;
  --background-color-alt: #eaf2fa;
  --background-color-light: #fff;

  --font: Ubuntu, sans-serif;
  --font-size: 1.1rem;
  --text-color: #8897a3;
  --text-color-alt: #3b3b3b;
  --text-color-light: #c2c2c2;

  --primary-color: #281d47;
  --primary-color-alt: #2642af;

  --secondary-color: #535353;
  --secondary-color-alt: #7a7a7a;

  --border-color: #d8dee6;

  /* named colors */
  --white: #fff;
}

body {
  font-family: var(--font);
  text-align: left;
  background: var(--background-color);
  color: var(--text-color);
  font-size: var(--font-size);
}

/* Base */
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: var(--primary-color);
  cursor: pointer;
}


h1,
h2,
h3,
h4,
h5 {
  color: var(--secondary-color);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.0rem;
}

h3 {
  margin-top: 3.5rem;
  font-size: 1.8rem;
}

h4 {
  margin: 0 0 1.5rem;
  font-size: 1.5rem;

}

hr {
  border: 1px solid var(--secondary-color-alt);
}

/* Sections */
section {
  display: block;
  padding: 2rem;
}

.section-content {
  width: 70%;
  margin: 0 auto;
  line-height: 1.6rem;
}

.intro {
  padding-top: 7rem;
}

.features,
.tabs,
.locations,
.map,
.footer {
  color: var(--text-color-alt);
  background: var(--background-color-alt);
}

/* Flex */
.flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
}

/* Branding */
.brand-name {
  margin-left: 2rem;
}

/* Button */
.button-wrapper {
  margin: 2rem 0;
}

.button {
  align-items: center;
  background-color: var(--primary-color);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-locale {
  margin-right: 3px;
  font-size: 0.8rem;
  min-height: 24px;
  padding: 0 8px;
}

.button:hover,
.button:focus {
  background-color: var(--primary-color-alt);
  color: #ffffff;
}

.button:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
  color: #fff;
}

.button:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}

/* Same Height Columns */
.col-wrapper {
  display: flex;
  justify-content: center;
  width: 70%;
  max-width: 100%;
  margin: auto;
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1 1 300px;
  /* In the order, equal to: flex-grow: 1; flex-shrink: 1; flex-basis: 300px; */
  margin: 1em;
}

.col-inline {
  display: flex;
  align-items: center;
}

/* Icons*/
.feature-icon {
  font-size: 9rem;
  color: #281d47;
  margin: 0 auto;
}

.how-icon {
  font-size: 42px;
  color: #281d47;
  margin-right: 1rem;
}

/* Helpers */
.mt-sm {
  margin-top: 1.25rem;
}

.mt-md {
  margin-top: 2.5rem;
}

.mt-lg {
  margin-top: 5rem;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.t-center {
  text-align: center;
}

.border {
  border: 1px solid #ccc;
}

@media screen and (max-width: 550px) {
  #tabs {
    padding: 1rem 0;
  }

  #locations,
  #map {
    padding: 0;
  }

  .section-content {
    width: 100%;
  }

  .col-wrapper {
    flex-wrap: wrap;
    width: 100%;
  }
}