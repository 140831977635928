/* Sticky-On-Scroll Header */
.header {
  position: sticky;
  top: 0;
  height: 6.4rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  z-index: 5;
}

.header-hide {
  top: -6.5rem;
}